import terms from 'assets/terms'
import { Loader } from 'components'

type Props = {
  progress: number
}

export function Loading({ progress }: Props) {
  return (
    <div className="loading flex-center">
      <Loader variant="large" progress={progress} />
      <div>
        <h2>{terms.Pages.Import.loadingTitle}</h2>
        <p>{terms.Pages.Import.loadingSubtitle}</p>
      </div>
    </div>
  )
}
