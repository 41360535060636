/* eslint-disable react/no-array-index-key */
import { useEffect, useRef, useState } from 'react'
import { MapError } from 'react-map-gl'
import lodash from 'lodash'
import MonacoEditor from '@monaco-editor/react'
import MAIN_API from 'config/config'
import { useAppDispatch } from 'utils'
import { Configuration, ProjectEditor, ProjectViewer } from 'types'
import { getProjectConfiguration } from 'services'
import { updateProject } from 'reducers/projects/slices'
import { Resizer } from 'components'
import { mapErrorSignal } from '../map/Map'

import './EditorPanel.scss'

type Props = {
  project: ProjectEditor | ProjectViewer
}

export default function EditorPanel({ project }: Props) {
  const editorRef = useRef<HTMLDivElement>(null)
  const dispatch = useAppDispatch()
  const [errorCount, setErrorCount] = useState<number | null>(null)
  const mapErrorCount = mapErrorSignal.value.length
  const configuration = getProjectConfiguration(project)
  const configurationString = JSON.stringify(configuration, null, 2)
  const [value, setValue] = useState<string>(configurationString)
  const debouncedSetValue = lodash.debounce(setValue, 300)
  const options = {
    readOnly: false,
    minimap: { enabled: false },
    selectOnLineNumbers: true,
    automaticLayout: true,
  }

  useEffect(() => {
    if (errorCount > 0 || value === configurationString) return
    let valueParsed: Configuration

    try {
      valueParsed = JSON.parse(value) as Configuration
    } catch (_) {
      return // Do nothing
    }

    mapErrorSignal.value = []

    dispatch(updateProject({
      ...project,
      templatable_configuration: valueParsed,
    }))
  }, [errorCount, value])

  const handleOnValidate = (errors: unknown[]) => setErrorCount(errors.length)
  const handleChange = (val: string) => debouncedSetValue(val)
  const handleErrorMessage = (error: MapError['error'] & { url: string}) => {
    if (!error) return null
    const status = error?.status
    return status ? `${status} : ${error.url.replace(`${MAIN_API.proxy}/chartis/v2/layer/`, '')}` : error.message
  }

  return (
    <div className="editor">
      <div className={`wrapper${mapErrorCount > 0 ? ' errors-displayed' : ''}`} ref={editorRef}>
        <MonacoEditor
          options={options}
          value={configurationString}
          theme="vs-dark"
          language="json"
          onChange={handleChange}
          onValidate={handleOnValidate}
        />
        <div className="errors">
          {mapErrorSignal?.value.map((error, index) => (
            <p key={`error-${index}`}>{`Error:   ${handleErrorMessage(error)}`}</p>
          ))}
        </div>
      </div>
      <Resizer wrapperRef={editorRef} />
    </div>
  )
}
