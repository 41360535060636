import { useCallback, useEffect, useState } from 'react'
import './Resizer.scss'

type Props = {
  wrapperRef: React.RefObject<HTMLElement>
}

export default function Resizer({ wrapperRef }: Props) {
  const [resizing, setResizing] = useState<boolean>(false)
  const handleStartResize = (event: MouseEvent) => {
    if (wrapperRef.current && event.target === document.getElementById('handle')) {
      event.preventDefault()
      setResizing(true)
    }
  }

  const handleEndResize = (event: MouseEvent) => {
    if (wrapperRef.current && event.target === document.getElementById('handle')) {
      event.preventDefault()
      setResizing(false)
    }
  }

  const handleResize = useCallback((event: MouseEvent) => {
    const { current } = wrapperRef
    const { clientX } = event
    const { innerWidth } = window

    if (resizing && current) {
      if (clientX > 7 && clientX < innerWidth - 7) {
        current.style.width = `${clientX}px`
      } else if (clientX < 7) {
        current.style.width = '7px'
        setResizing(false)
      } else if (clientX > innerWidth - 7) {
        current.style.width = `${innerWidth - 15}px`
        setResizing(false)
      }
    } else {
      setResizing(false)
    }
  }, [resizing, wrapperRef])

  const handleDoubleClick = () => {
    if (wrapperRef.current) {
      wrapperRef.current.style.width = '370px'
    }
  }

  useEffect(() => {
    if (wrapperRef.current) {
      document.addEventListener('mousedown', handleStartResize)
      document.addEventListener('mouseup', handleEndResize)
      document.addEventListener('mousemove', handleResize)
    }

    return () => {
      document.removeEventListener('mousedown', handleStartResize)
      document.removeEventListener('mouseup', handleEndResize)
      document.removeEventListener('mousemove', handleResize)
    }
  }, [wrapperRef.current, handleResize])

  return (
    <div id="handle" className="flex-center" onDoubleClick={handleDoubleClick}>
      <div />
      <div />
    </div>
  )
}
