import { Signal } from '@preact/signals-react'
import { useEffect, useState } from 'react'
import { ReactComponent as Info } from 'assets/icons/info-outlined.svg'
import { ReactComponent as Success } from 'assets/icons/check-circle-outlined.svg'
import { ReactComponent as Error } from 'assets/icons/blocked-outlined.svg'
import { ReactComponent as Warning } from 'assets/icons/warning-outlined.svg'
import { ReactComponent as Close } from 'assets/icons/close.svg'

import './ToastMessage.scss'

export enum ToastLevel {
  INFO = 'info',
  ERROR = 'error',
  SUCCESS = 'success',
  WARNING = 'warning',
}

/**
 * Signal used to update the toast message
 * It can be controlled from any component
 */
export const toastSignal = new Signal<{
  message: string,
  severity?: ToastLevel,
  timeout?: number,
}>(undefined)

export default function ToastMessage() {
  const { message, severity = ToastLevel.INFO, timeout = 6000 } = toastSignal.value || {}
  const [displayed, setDisplayed] = useState(false)
  const animationDuration = 300

  const handleHide = () => {
    setDisplayed(false)
    setTimeout(() => {
      toastSignal.value = undefined
    }, animationDuration)
  }

  useEffect(() => {
    if (!message) {
      return () => {
        /* do not show toast if message is empty */
      }
    }

    setDisplayed(true)

    const timer = setTimeout(() => {
      handleHide()
    }, timeout)

    return () => {
      clearTimeout(timer)
    }
  }, [toastSignal.value])

  const getIcon = () => {
    switch (severity) {
      case ToastLevel.ERROR:
        return <Error />
      case ToastLevel.SUCCESS:
        return <Success />
      case ToastLevel.WARNING:
        return <Warning />
      default:
        return <Info />
    }
  }

  return (
    <div
      className={`toast ${severity}${displayed ? ' displayed' : ''}`}
      style={{ transitionDuration: `${animationDuration}ms` }}
    >
      {getIcon()}
      <p>{message}</p>
      <Close onClick={handleHide} />
    </div>
  )
}
