/* eslint-disable max-len */
export const terms = {
  Common: {
    loading: 'Chargement',
    updated: 'Modifié',
    sortBy: 'Trier par',
    cancel: 'Annuler',
    save: 'Sauvegarder',
    copyLink: 'Copier le lien',
    linkCopied: 'Lien copié dans le presse-papier',
    invit: 'Inviter',
    READ_ONLY: 'Lecture',
    READ_WRITE: 'Écriture',
    true: 'Oui',
    false: 'Non',
  },
  Error: {
    forbidden: {
      appTitle: 'Vous n\'avez pas les droits nécessaires pour accéder à cette application.',
      appSubtitle: 'Veuillez contacter votre administrateur.',
      content: 'Vous n\'avez pas les droits pour accéder à cette ressource. Veuillez réessayer ou contacter votre administrateur.',
      exitButton: 'Retour au portail des applications',
    },
    httpStatus: {
      forbidden: 'Vous n\'avez pas les droits pour effectuer cette action.',
      notFound: 'La ressource demandée n\'existe pas.',
      internalServerError: 'Erreur de traitement de la requête côté serveur.',
      badGateway: 'Le serveur est indisponible.',
      unknownError: 'Une erreur inconnue est survenue.',
      badRequest: 'Vérifiez les informations que vous avez entrées et réessayez.',
    },
  },
  SideMenu: {
    links: {
      myViz: 'Mes visualisations',
      help: 'Demande d’assistance',
    },
  },
  Pages: {
    Projects: {
      title: 'Mes visualisations',
      Cards: {
        actionButton: 'Accéder à la visualisation',
      },
      searchPlaceholder: 'Rechercher',
      dropdownLabels: {
        date: 'Date de modification',
        alpha: 'Ordre alphabétique',
      },
    },
    Project: {
      Map: {
        fallbackError: 'Erreur de traitement de la carte, veuillez recharger la page ou changer de visualisation',
        zoom: 'Zoom',
      },
      Config: {
        cardHeaderTitle: 'Fonds de carte',
        cardTitle: (index: number) => `Fond de carte ${index}`,
      },
      Layer: {
        slugNotFound: 'Erreur : <layer_slug> non trouvé',
      },
      Table: {
        filters: 'Filtres',
      },
    },
    Import: {
      title: 'Importer mes données',
      info: 'Importer le fichier Excel qui contient les données que je souhaite spatialiser et visualiser sur une carte.',
      loadingTitle: 'Veuillez patienter quelques instants...',
      loadingSubtitle: 'Vos données sont en cours d’importation. Cette opération peut prendre jusqu’à une minute.',
      fileExampleButton: 'Voir un exemple de fichier',
      uploadFormat: 'Format attendu :<br /><span>.xls, .csv</span>',
      successTitle: 'Succès',
      successSubtitle: 'Votre fichier a bien été importé',
      successButton: 'Visualiser mes données',
      errorTitle: 'Erreur',
      errorSubtitle: 'Votre fichier n’a pas pu être importé',
      errorPartiallyRejected: 'Une partie des données n’a pas pu être importée',
      errorNoData: 'Aucune donnée à afficher',
      backButton: 'Retour à l’écran d’import',
      showDataButton: 'Visualiser mes données',
      processedLines: 'Lignes traitées',
    },
  },
  Modals: {
    Save: {
      title: (name: string) => `Sauvegarder les modifications apportées à “${name}” ?`,
    },
    Share: {
      title: (name: string) => `Partager “${name}”`,
    },
    Filter: {
      add: 'Ajouter un filtre',
      remove: 'Supprimer',
      column: 'Colonne',
      operator: 'Opérateur',
      value: 'Valeur',
      operators: {
        eq: 'égal à',
        isnull: 'est vide',
        gt: 'supérieur à',
        gte: 'supérieur ou égal à',
        gt_or_null: 'supérieur à, ou vide',
        gte_or_null: 'supérieur ou égal à, ou vide',
        lt: 'inférieur à',
        lte: 'inférieur ou égal à',
        lt_or_null: 'inférieur à, ou vide',
        lte_or_null: 'inférieur ou égal à, ou vide',
        neq: 'différent de',
        in: 'contient',
        ilike: 'contient',
        overlaps: 'contient',
      },
    },
  },
  Messages: {
    success: {
      projectSaved: 'Les modifications ont été sauvegardées.',
      projectShared: (slug: string, name: string) => `Le projet “${slug}“ a été partagé avec ${name}.`,
      fileProcess: 'Le fichier a été traité avec succès.',
    },
    errors: {
      fileUpload: 'Erreur lors de l’importation du fichier.',
      fileProcess: 'Erreur lors du traitement du fichier.',
      getChartisApplyFilterError: 'Erreur lors de l’application des filtres.',
    },
  },
} as const

export default terms
