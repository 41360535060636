import { Popup } from 'react-map-gl'

type Props = {
  coordinate: [number, number]
  properties: {
    [name: string]: string
  }
  onClose: () => void
}

export default function MapPopup({ coordinate, properties, onClose }: Props) {
  return (
    <Popup
      className="map-popup"
      longitude={coordinate[0]}
      latitude={coordinate[1]}
      captureScroll
      closeButton={false}
      onClose={onClose}
    >
      {Object.entries(properties).map(([name, value]) => (
        <div key={name}>
          <b>{`${name} : `}</b>
          {value}
        </div>
      ))}
    </Popup>
  )
}
