import { ReactElement, useState } from 'react'
import { useNavigate } from 'react-router-dom'
import moment from 'moment'
import { Project } from 'types'
import terms from 'assets/terms'
import { Button, DropDown, DropDownType, MenuDropDown } from 'components'

import './ProjectCard.scss'

type Props = {
  project: Project
  pending?: boolean
}

function Card({ project, pending }: Props): ReactElement {
  const navigate = useNavigate()
  const [anchorEl, setAnchorEl] = useState<null | HTMLElement>(null)

  const dropdownActions = [
    {
      label: 'Favoris',
      handleClick: () => { /* TODO: Implement */ },
    },
    {
      label: 'Dupliquer',
      handleClick: () => { /* TODO: Implement */ },
    },
    {
      label: 'Modifier',
      handleClick: () => { /* TODO: Implement */ },
    },
    {
      label: 'Supprimer',
      handleClick: () => { /* TODO: Implement */ },
    },
  ]

  const handleNavigation = () => {
    navigate(`/projects/${project.slug}`)
  }

  return (
    <div className={`card-project ${pending ? 'pending' : ''}`}>
      <DropDown
        type={DropDownType.DOTS}
        anchorEl={anchorEl}
        setAnchorEl={setAnchorEl}
      >
        <MenuDropDown actions={dropdownActions} handleClose={() => setAnchorEl(null)} />
      </DropDown>
      <h2>{project.name}</h2>
      <h3>{terms.Common[project.shared_as]}</h3>
      <p>{project.description}</p>
      <span>{`${terms.Common.updated} : ${moment(project.last_modified).format('DD/MM/YYYY')}`}</span>
      <Button text={terms.Pages.Projects.Cards.actionButton} onClick={handleNavigation} />
    </div>
  )
}

Card.defaultProps = {
  pending: false,
}

export default Card
