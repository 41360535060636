/* eslint-disable max-len */
import { useNavigate } from 'react-router-dom'
import { ReactComponent as Code } from 'assets/icons/code.svg'
import { ReactComponent as Config } from 'assets/icons/config.svg'
import { ReactComponent as Layers } from 'assets/icons/layers.svg'
import { ReactComponent as Pdf } from 'assets/icons/pdf.svg'
import { ReactComponent as Save } from 'assets/icons/save.svg'
import { ReactComponent as Share } from 'assets/icons/share.svg'
import { ReactComponent as Table } from 'assets/icons/table.svg'
import terms from 'assets/terms'
import { useAppDispatch } from 'utils'
import { getProjectConfiguration, useRoles } from 'services'
import { ActivePanel, ProjectEditor, ProjectViewer } from 'types'
import { postConfiguration } from 'reducers/projects/thunks'
import {
  Button, ButtonStyle, Loader, SaveModal, ShareModal, modalSignal,
} from 'components'
import { mapShouldPrintSignal, mapSourceLoadingSignal } from '../map/Map'
import { tableSourceLoadingSignal } from '../table/TablePanel'
import Action from './HeaderAction'

import './ProjectHeader.scss'

type Props = {
  project: ProjectEditor | ProjectViewer
  active: ActivePanel
  onChangePanel: (action: ActivePanel) => void
}

export default function ProjectHeader({ project, active, onChangePanel }: Props) {
  const configuration = getProjectConfiguration(project)
  const dispatch = useAppDispatch()
  const navigate = useNavigate()
  const { isAdmin } = useRoles()
  const shouldDisplayTable = configuration?.layers?.filter(layer => layer?.show_as_table).length > 0

  const handleDisplayPanel = (action: ActivePanel) => () => {
    onChangePanel(action)
  }

  const handleActionSave = () => {
    modalSignal.value = (
      <SaveModal
        projectName={project?.name}
        onConfirm={async () => {
          await dispatch(postConfiguration(project.slug, configuration))
          modalSignal.value = undefined
        }}
      />
    )
  }

  const handleActionPDF = () => {
    mapShouldPrintSignal.value = true
  }

  const handleActionShare = () => {
    modalSignal.value = <ShareModal project={project} />
  }

  const handleDisplayLoader = () => mapSourceLoadingSignal.value || tableSourceLoadingSignal.value

  return (
    <div className="project-header">
      <Action Icon={Code} action={handleDisplayPanel(ActivePanel.CODE)} active={active === ActivePanel.CODE} hidden={!isAdmin} />
      <Action Icon={Config} action={handleDisplayPanel(ActivePanel.CONFIG)} active={active === ActivePanel.CONFIG} />
      <Action Icon={Layers} action={handleDisplayPanel(ActivePanel.LAYERS)} active={active === ActivePanel.LAYERS} />
      <Action Icon={Table} action={handleDisplayPanel(ActivePanel.TABLE)} active={active === ActivePanel.TABLE} hidden={!shouldDisplayTable} />
      <Action Icon={Share} action={handleActionShare} />
      <Action Icon={Pdf} action={handleActionPDF} />
      <Action Icon={Save} action={handleActionSave} />
      <Button
        text={terms.Pages.Import.title}
        style={ButtonStyle.light}
        onClick={() => navigate(`/projects/${project.slug}/import`)}
      />
      {handleDisplayLoader() && <Loader variant="small" />}
    </div>
  )
}
