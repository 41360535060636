/* eslint-disable camelcase */
import { useEffect, useState } from 'react'
import { signal } from '@preact/signals-react'
import moment from 'moment'
import { DataGrid, GridColDef, GridValueGetterParams, useGridApiRef } from '@mui/x-data-grid'
import {
  Filter,
  GeojsonResponse,
  ProjectEditor,
  ProjectViewer,
  SqlType,
} from 'types'
import {
  getChartisLayer,
  getProjectConfiguration,
  useRoles,
  getTypeForSqlType,
  getLayerInfo,
} from 'services'
import { ReactComponent as Previous } from 'assets/icons/previous.svg'
import { ReactComponent as Next } from 'assets/icons/next.svg'
import terms from 'assets/terms'
import { useAppDispatch } from 'utils'
import { getProject, postConfiguration } from 'reducers/projects/thunks'
import { Button, FiltersModal, SelectInput, modalSignal } from 'components'

import './TablePanel.scss'

type Props = {
  project: ProjectEditor | ProjectViewer
}

/**
 * Signal used to communicate with header loading component
 * This signal is controlled by next and previous actions
 * (this component is located in src/pages/project/header/index.tsx)
 */
export const tableSourceLoadingSignal = signal<boolean>(false)

export default function TablePanel({ project }: Props) {
  const apiRef = useGridApiRef()
  const dispatch = useAppDispatch()
  const role = useRoles()
  const [page, setPage] = useState(0)
  const [fetchResponse, setFetchResponse] = useState<GeojsonResponse>({
    features: [],
    next: null,
    previous: null,
  })
  const configuration = getProjectConfiguration(project)
  const layers = configuration?.layers
  const [displayedIndex, setDisplayedIndex] = useState(layers?.findIndex(layer => layer?.show_as_table))
  const currentLayer = layers?.[displayedIndex]
  const { view_slug, filters } = currentLayer
  const chartisLayerInfo = getLayerInfo(currentLayer)
  const fields = chartisLayerInfo.fields_details.filter(({ views }) => views?.includes(view_slug))

  useEffect(() => {
    const fetchPage = async () => {
      tableSourceLoadingSignal.value = true
      const response = await getChartisLayer(chartisLayerInfo.name, view_slug, page, filters)
      setFetchResponse(response)
      tableSourceLoadingSignal.value = false
    }

    if (currentLayer) {
      fetchPage()
    }
  }, [currentLayer, page, filters])

  const getColumns = (): GridColDef[] => {
    if (!fetchResponse?.features) return []

    return fields.map(({ name, type }) => ({
      field: name,
      headerName: name,
      width: 150,
      sortable: false,
      type: getTypeForSqlType(type as SqlType),
      valueGetter: (params: GridValueGetterParams) => {
        if (getTypeForSqlType(type as SqlType) === 'date') {
          return moment(params.row.properties[name]).toDate()
        }
        return params.row.properties[name]
      },
    }))
  }

  const getRows = () => {
    if (!fetchResponse) return []

    return fetchResponse.features.map((feature, index) => ({
      id: index,
      ...feature,
    }))
  }

  const getLayersOptions = () => layers.map((layer, i) => {
    if (layer.show_as_table) {
      return {
        label: `${layer.collection_slug} - ${getLayerInfo(layer).description}`,
        value: i,
      }
    }

    return undefined
  }).filter(Boolean)

  const handleLayerChange = (layerIndex: number) => {
    apiRef.current.scrollToIndexes({ rowIndex: 0, colIndex: 0 })
    setFetchResponse({ features: [], next: null, previous: null })
    setDisplayedIndex(layerIndex)
    setPage(0)
  }

  const handleSaveFilters = async (newFilters: Filter[]) => {
    const newLayers = layers.map((layer, i) => {
      if (i === Number(displayedIndex)) {
        return { ...layer, filters: newFilters }
      }
      return layer
    })

    await dispatch(postConfiguration(project.slug, { ...configuration, layers: newLayers }))
    await dispatch(getProject(project.slug, role.hasOnlyAccess))
    modalSignal.value = undefined
  }

  const handleDisplayFilters = () => {
    modalSignal.value = (
      <FiltersModal
        layerFields={fields}
        filters={filters}
        onSave={handleSaveFilters}
      />
    )
  }

  return (
    <div className="table">
      <DataGrid
        apiRef={apiRef}
        rows={getRows()}
        columns={getColumns()}
        pageSizeOptions={[]}
        filterMode="server"
        hideFooter
        disableColumnMenu
      />
      <div className="actions">
        <div className="filters">
          <Button
            disabled={tableSourceLoadingSignal.value}
            onClick={handleDisplayFilters}
            text={terms.Pages.Project.Table.filters}
          />
        </div>
        <SelectInput
          defaultValue={chartisLayerInfo.name}
          options={getLayersOptions()}
          onChange={handleLayerChange}
        />
        <Previous
          className={(!fetchResponse.previous || tableSourceLoadingSignal.value) ? 'disabled' : ''}
          onClick={() => setPage(page - 1)}
        />
        <Next
          className={(!fetchResponse.next || tableSourceLoadingSignal.value) ? 'disabled' : ''}
          onClick={() => setPage(page + 1)}
        />
      </div>
    </div>
  )
}
