import { signal } from '@preact/signals-react'
import { PORTAL_URL } from 'config/config'
import dgexLogo from 'assets/logos/dgex.svg'
import pdiLogo from 'assets/logos/pdi.svg'
import { sideMenuOpenedSignal } from 'components'
import Burger from './burger/Burger'
import AccountMenu from './MenuAccount'

import './TopBar.scss'

type Props = {
  appName: string;
  hasAccess: boolean;
}

/**
 * Signal used to update the top bar title
 * It can be controlled from any component
 */
export const topBarTitleSignal = signal<string>('')

export default function TopBar({ appName, hasAccess }: Props) {
  const toggleMenu = () => {
    sideMenuOpenedSignal.value = !sideMenuOpenedSignal.value
  }

  return (
    <header className={!hasAccess ? 'access-denied' : ''}>
      {hasAccess && <Burger opened={sideMenuOpenedSignal.value} action={toggleMenu} />}
      <div className="logos">
        <a className="flex-center" href={PORTAL_URL.url}>
          <img alt={appName} src={dgexLogo} />
        </a>
        <img alt={appName} src={pdiLogo} />
      </div>
      <p className="absolute-center">{topBarTitleSignal.value}</p>
      <AccountMenu />
    </header>
  )
}
