import terms from 'assets/terms'

/* Fallback UI used in ErrorBoundary for MapGL */
export default function FallbackComponent() {
  return (
    <div className="flex-center" style={{ width: '100%', padding: '20px', textAlign: 'center' }}>
      {terms.Pages.Project.Map.fallbackError}
    </div>
  )
}
