import { useState } from 'react'

import './CheckInput.scss'

type Props = {
  label: string
  defaultValue: boolean
  onChange: (checked: boolean) => void
}

export default function CheckInput({ label, defaultValue, onChange }: Props) {
  const [value, setValue] = useState(defaultValue)

  const handleChange = () => {
    onChange(!value)
    setValue(!value)
  }

  return (
    <div className="checkbox input-wrapper">
      <input
        id={label}
        type="checkbox"
        checked={value}
        onChange={handleChange}
      />
      <label htmlFor={label}>{label}</label>
    </div>
  )
}
