/* eslint-disable camelcase */
import { useEffect, useRef, useState } from 'react'
import { useParams, useNavigate } from 'react-router-dom'
import { PayloadAction } from '@reduxjs/toolkit'
import { terms } from 'assets/terms'
import { Task, TaskStatus } from 'types'
import { topBarTitleSignal, sideMenuOpenedSignal, toastSignal, ToastLevel } from 'components'
import { getFileStatus, getProject } from 'reducers/projects/thunks'
import { useRoles } from 'services'
import { useAppDispatch } from 'utils'
import { Loading } from './Loading'
import { Error } from './Error'
import { Success } from './Success'
import { Upload } from './Upload'
import { Rejected } from './Rejected'

import './ImportPage.scss'

export default function Import() {
  const navigate = useNavigate()
  const dispatch = useAppDispatch()
  const params = useParams<{ slug: string }>()
  const { hasOnlyAccess } = useRoles()
  const [task, setTask] = useState<Task>()
  const isCalling = useRef(false)

  useEffect(() => {
    (async () => {
      const response = await dispatch(
        getProject(params.slug, hasOnlyAccess),
      ) as PayloadAction<null, null, null, { message: string }>

      if (response?.error?.message) {
        navigate('/projects')
      }
    })()
    topBarTitleSignal.value = terms.Pages.Import.title
  }, [])

  useEffect(() => {
    let checkTaskInterval: NodeJS.Timeout | undefined

    const fetchTaskStatus = async () => {
      if (isCalling.current) return
      isCalling.current = true

      const response = await dispatch(getFileStatus(params.slug, task.uuid)) as PayloadAction<Task>
      const { status, progress, rejects, reason, row_count } = response.payload || {}
      isCalling.current = false

      if ([TaskStatus.REJECTED, TaskStatus.PARTIALLY_REJECTED].includes(status)) {
        toastSignal.value = { message: terms.Messages.errors.fileProcess, severity: ToastLevel.ERROR }
      } else if (status === TaskStatus.ACCEPTED) {
        toastSignal.value = { message: terms.Messages.success.fileProcess, severity: ToastLevel.SUCCESS }
      }

      if (status !== task.status || progress !== task.progress) {
        setTask({ uuid: task.uuid, status, progress, rejects, reason, row_count })
      }

      if (status !== TaskStatus.PENDING) {
        clearInterval(checkTaskInterval)
      }
    }

    if (task?.status === TaskStatus.PENDING && !checkTaskInterval) {
      checkTaskInterval = setInterval(fetchTaskStatus, 5000)
    }

    return () => {
      clearInterval(checkTaskInterval)
    }
  }, [task])

  const handleReset = () => {
    window.location.reload()
  }

  const handeShowData = () => {
    navigate(`/projects/${params?.slug}`)
  }

  const renderContent = () => {
    switch (task?.status) {
      case TaskStatus.PENDING:
      case TaskStatus.IDLE:
        return <Loading progress={task?.progress || 0} />
      case TaskStatus.REJECTED:
        return <Error onReset={handleReset} reason={task?.reason} />
      case TaskStatus.PARTIALLY_REJECTED:
        return <Rejected onNavigate={handeShowData} onReset={handleReset} rejected={task?.rejects} />
      case TaskStatus.ACCEPTED:
        return <Success rowCount={task?.row_count} onNavigate={handeShowData} />
      default:
        return <Upload onTaskChange={setTask} slug={params?.slug} />
    }
  }

  return (
    <div id="import" className={sideMenuOpenedSignal.value ? 'side-menu-opened' : ''}>
      {renderContent()}
    </div>
  )
}
