/* eslint-disable camelcase */
import { get } from '@osrdata/app_core/dist/requests'
import { signal } from '@preact/signals-react'
import {
  Filter, GeojsonResponse, ChartisInfo, SqlType, DataGridType, FilterOperators, Layer,
} from 'types'
import terms from 'assets/terms'
import { ToastLevel, toastSignal } from 'components'
import { getParamsForFilters } from './projects'

/**
 * Signal used to communicate with project/layer component
 * which is responsible for updating the layer name based on chartis info response
 * (this component is located in src/pages/project/layers/index.tsx)
 */
export const chartisInfoSignal = signal<ChartisInfo[]>([])
export const getChartisInfo = async () => {
  const response = await get('/chartis/v2/info/')
  chartisInfoSignal.value = Object
    .values(response)
    .flatMap(value => value)
    .map(({ name, description, fields_details }: ChartisInfo) => ({ name, description, fields_details }))
}

/**
 * Fetch chartis layer as geojson
 *
 * @param source chartis source
 * @param view chartis view
 * @param page page number
 * @param filters filters to apply
 * @returns chartis layer as GeojsonResponse
 */
export const getChartisLayer = async (
  source: string,
  view: string,
  page: number,
  filters: Filter[] = [],
): Promise<GeojsonResponse> => {
  const params = getParamsForFilters(filters)
  params.append('page', page.toString())
  params.append('page_size', '50')

  try {
    return await get(`/chartis/v2/layer/${source}/geojson/${view}`, params)
  } catch (error) {
    toastSignal.value = { message: terms.Messages.errors.getChartisApplyFilterError, severity: ToastLevel.ERROR }
    return { features: [], next: null, previous: null }
  }
}

/**
 * Get layer info based on chartis info response
 *
 * @param layer layer to get info
 * @returns chartis info
 */
export const getLayerInfo = (layer: Layer): ChartisInfo => {
  const { collection_slug, layer_slug } = layer
  const chartisInfo = chartisInfoSignal.value?.find(({ name }) => name === `${collection_slug}_${layer_slug}`)
  chartisInfo.description = chartisInfo.description || layer_slug || terms.Pages.Project.Layer.slugNotFound
  return chartisInfoSignal.value?.find(({ name }) => name === `${collection_slug}_${layer_slug}`)
}

/**
 * Get DataGrid type for chartis sql type in order to generate type for DataGrid columns
 *
 * @param sqlType chartis sql type input
 * @returns data grid type
 */
export const getTypeForSqlType = (sqlType: SqlType): DataGridType => {
  switch (sqlType) {
    case 'boolean':
      return 'boolean'
    case 'timestamp_with_tz':
      return 'date'
    case 'integer':
    case 'double':
    case 'bigint':
      return 'number'
    case 'array_uuid':
    case 'array_text':
    case 'array_varchar_40':
    case 'array_char_36':
    case 'array_integer':
    case 'array_varchar_6':
      return 'array'
    default:
      return 'string'
  }
}

/**
 * This function aims to update the available operators list based on field column type
 *
 * @param type DataGrid type
 * @returns list of handled operators
 */
export const getHandledOperatorForType = (type: DataGridType): FilterOperators[] => {
  switch (type) {
    case 'array':
      return [FilterOperators.OVERLAPS]
    case 'string':
      return [FilterOperators.EQ, FilterOperators.NEQ, FilterOperators.ISNULL, FilterOperators.ILIKE]
    case 'boolean':
      return [FilterOperators.EQ, FilterOperators.NEQ, FilterOperators.ISNULL]
    case 'date':
    case 'number':
      return [
        FilterOperators.EQ, FilterOperators.NEQ, FilterOperators.ISNULL,
        FilterOperators.GT, FilterOperators.GTE, FilterOperators.LT, FilterOperators.LTE,
      ]
    default:
      return []
  }
}
