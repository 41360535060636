import { Dispatch, ReactElement, SetStateAction } from 'react'
import Menu from '@mui/material/Menu'
import MoreHorizIcon from '@mui/icons-material/MoreHoriz'
import ExpandMoreIcon from '@mui/icons-material/ExpandMore'

import './DropDown.scss'

interface DropDownProps {
  type: DropDownType;
  children: ReactElement[] | ReactElement;
  anchorEl: HTMLElement | null;
  setAnchorEl: Dispatch<SetStateAction<HTMLElement>>;
  label?: string;
  disabled?: boolean;
}

export enum DropDownType {
  DOTS = 'dots',
  CHEVRON = 'chevron',
  CHEVRON_LIGHT = 'chevron-light',
}

function DropDown({
  type, children, anchorEl, setAnchorEl, label, disabled,
}: DropDownProps): ReactElement {
  const open = Boolean(anchorEl)
  const handleClick = (event: React.MouseEvent<HTMLDivElement>) => {
    setAnchorEl(event.currentTarget)
  }

  const handleClose = () => {
    setAnchorEl(null)
  }

  return (
    <div className={`simple-dropdown dropdown--${type}${disabled ? ' disabled' : ''}`}>
      <div className="dropdown-wrapper" onClick={handleClick}>
        {label && <span className="label">{label}</span>}
        <button
          className="drop-down-button flex-center"
          type="button"
        >
          {type === DropDownType.DOTS ? <MoreHorizIcon /> : <ExpandMoreIcon />}
        </button>
      </div>
      <Menu
        id="basic-menu"
        anchorEl={anchorEl}
        open={open}
        onClose={handleClose}
        MenuListProps={{
          'aria-labelledby': 'basic-button',
        }}
      >
        {children}
      </Menu>
    </div>
  )
}

DropDown.defaultProps = {
  label: '',
  disabled: false,
}

export default DropDown
