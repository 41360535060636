import { useState } from 'react'
import { v4 as uuidv4 } from 'uuid'
import moment from 'moment'
import terms from 'assets/terms'
import { getTypeForSqlType, getHandledOperatorForType } from 'services'
import {
  ChartisField, DataGridType, Filter, FilterOperators, Option, SqlType,
} from 'types'
import { Button, ButtonStyle, SelectInput, TextInput, DateInput } from 'components'
import { modalSignal } from '../ModalWrapper'

import './FiltersModal.scss'

type Props = {
  layerFields: ChartisField[]
  filters: Filter[]
  onSave: (filters: Filter[]) => void
}

type FilterWithId = Filter & { id: string, type: DataGridType }

/*
 * List of operators that are not handled by table's filters
 * Those operators are not displayed in the modal, but still applied under the hood
 */
const unhandledOperators = [
  FilterOperators.BBOX, FilterOperators.BPOLYGON, FilterOperators.GEOM_TYPE_IN, FilterOperators.LIKE,
  FilterOperators.IN, FilterOperators.GTE_OR_NULL, FilterOperators.LTE_OR_NULL, FilterOperators.GT_OR_NULL,
  FilterOperators.LT_OR_NULL,
]

export default function FiltersModal({ layerFields, filters, onSave }: Props) {
  const getType = (field: string) => getTypeForSqlType(layerFields.find(f => f.name === field)?.type as SqlType)
  const [filtersState, setFiltersState] = useState<FilterWithId[]>(filters?.map(
    filter => ({ ...filter, id: uuidv4(), type: getType(filter.field) }),
  ) || [])

  const handleHideModal = () => {
    modalSignal.value = undefined
  }

  const handleAddFilter = () => {
    setFiltersState([...filtersState, {
      field: layerFields[0].name,
      operator: FilterOperators.EQ,
      value: '',
      id: uuidv4(),
      type: getType(layerFields[0].name),
    }])
  }

  const handleRemoveFilter = (id: string) => () => {
    setFiltersState(filtersState.filter(filter => filter.id !== id))
  }

  const handleChangeField = (id: string) => (field: string) => {
    const type = getType(field)
    const handledOperators = getHandledOperatorForType(type)

    // Update operator if the new field doesn't support the current operator
    let operator = filtersState.find(f => f.id === id)?.operator
    if (!handledOperators.includes(operator)) {
      [operator] = handledOperators
    }

    setFiltersState(filtersState.map(
      filter => (filter.id === id ? { ...filter, field, type, operator } : filter),
    ))
  }

  const handleChangeOperator = (id: string) => (operator: FilterOperators) => {
    setFiltersState(filtersState.map(
      filter => (filter.id === id ? { ...filter, operator } : filter),
    ))
  }

  const handleChangeValue = (id: string) => (value: string) => {
    setFiltersState(filtersState.map(
      filter => (filter.id === id ? { ...filter, value } : filter),
    ))
  }

  const handleSave = () => {
    const sanitizedFilters: Filter[] = filtersState
      .filter(filter => filter.value !== '')
      .map(filter => ({ field: filter.field, operator: filter.operator, value: filter.value }))

    onSave(sanitizedFilters)
    handleHideModal()
  }

  const getDefaultOperator = (filter: Filter) => Object.values(FilterOperators).find(
    val => val === filter.operator,
  ) || FilterOperators.EQ

  const getHandledFilters = () => filtersState.filter(
    ({ operator }) => !unhandledOperators.includes(operator),
  )

  const getOperatorsOptions = (field: string): Option[] => {
    const operatorLabels = terms.Modals.Filter.operators

    return Object.values(FilterOperators).filter(
      operator => getHandledOperatorForType(getType(field)).includes(operator),
    ).map(operator => ({ label: operatorLabels[operator], value: operator }))
  }

  const getColumnOptions = (): Option[] => layerFields.map(field => ({ value: field.name }))

  const getValueInput = (filter: FilterWithId, onChange: (value: string) => void) => {
    const { field, value, operator } = filter
    const type = getType(field)
    const props = { label: terms.Modals.Filter.value, defaultValue: value, onChange }

    if (type === 'boolean' || operator === FilterOperators.ISNULL) {
      const booleanOptions = ['true', 'false'].map(val => ({ label: terms.Common[val], value: val }))
      return <SelectInput {...props} options={booleanOptions} shouldReset={!['true', 'false'].includes(value)} />
    } if (type === 'date') {
      return <DateInput {...props} shouldReset={!moment(value).isValid()} />
    } if (type === 'number') {
      return <TextInput {...props} type="number" align="center" shouldReset={!/^\d+$/.test(value)} />
    }
    return <TextInput {...props} align="center" shouldReset={['true', 'false'].includes(value)} />
  }

  return (
    <div className="filters-modal content">
      <div className="flex-column-center filters">
        {getHandledFilters().map(filter => (
          <div key={filter.id} className="flex-center filter">
            <SelectInput
              label={terms.Modals.Filter.column}
              defaultValue={filter.field}
              options={getColumnOptions()}
              onChange={handleChangeField(filter.id)}
            />
            <SelectInput
              label={terms.Modals.Filter.operator}
              defaultValue={getDefaultOperator(filter)}
              options={getOperatorsOptions(filter.field)}
              onChange={handleChangeOperator(filter.id)}
            />
            {getValueInput(filter, handleChangeValue(filter.id))}
            <Button
              style={ButtonStyle.borderLess}
              text="&#x2715;"
              onClick={handleRemoveFilter(filter.id)}
            />
          </div>
        ))}
        <Button style={ButtonStyle.borderLess} text={terms.Modals.Filter.add} onClick={handleAddFilter} />
      </div>
      <div className="flex-center actions">
        <Button style={ButtonStyle.light} onClick={handleHideModal} text={terms.Common.cancel} />
        <Button onClick={handleSave} text={terms.Common.save} />
      </div>
    </div>
  )
}
