import { ReactElement } from 'react'
import Button from '@mui/material/Button'

import './Button.scss'

export enum ButtonStyle {
  primary = 'primary',
  light = 'light',
  borderLess = 'borderLess',
}

type Props = {
  text: string;
  onClick?: () => void;
  style?: ButtonStyle;
  disabled?: boolean;
  icon?: string;
}

export default function SimpleButton({
  text, onClick, style, disabled, icon,
}: Props): ReactElement {
  return (
    <Button
      className={`simple-button button--${style}${disabled ? ' disabled' : ''}`}
      variant="contained"
      size="large"
      onClick={onClick}
      disabled={disabled}
    >
      {!icon && icon}
      {text}
    </Button>
  )
}

const defaultProps = {
  style: ButtonStyle.primary,
  disabled: false,
  icon: null,
  onClick: () => { /* to implement */ },
}

SimpleButton.defaultProps = defaultProps
