import { ReactComponent as BigCheck } from 'assets/icons/big-check.svg'

type Props = {
  title: string
  handleChangeMap: () => void
  backgroundImage: string
  isSelected: boolean
}

export default function Card({
  title, handleChangeMap, backgroundImage, isSelected,
}: Props) {
  return (
    <div
      className={`map-type${isSelected ? ' selected' : ''}`}
      style={{ backgroundImage: `url(${backgroundImage})` }}
      onClick={handleChangeMap}
    >
      <span>{title}</span>
      <div className="shadow" />
      <BigCheck />
    </div>
  )
}
