import { PayloadAction, createSlice } from '@reduxjs/toolkit'
import { getProjectConfiguration } from 'services'
import { ProjectEditor, ProjectViewer, TaskStatus } from 'types'

export type ProjectState = {
  list: (ProjectEditor | ProjectViewer)[]
  loadingProjects: boolean
  tasks: {
    uuid: string
    project: string
    status?: TaskStatus
  }[]
}

const initialState: ProjectState = {
  list: [],
  tasks: [],
  loadingProjects: false,
}

const projectsSlice = createSlice({
  name: 'projects',
  initialState,
  reducers: {
    setProjects(state, action: PayloadAction<ProjectEditor[] | ProjectViewer[]>) {
      state.list = action.payload
    },
    updateProject(state, action: PayloadAction<ProjectEditor | ProjectViewer>) {
      const projectIndex = state.list.findIndex(project => project.slug === action.payload.slug)

      if (projectIndex === -1) {
        state.list.push(action.payload)
      } else {
        state.list[projectIndex] = action.payload
      }
    },
    updateLayerDisplay(state, action: PayloadAction<{ projectSlug: string, layerIndex: number, showLayer: boolean }>) {
      const { projectSlug, layerIndex, showLayer } = action.payload
      const project = state.list.find(p => p.slug === projectSlug)
      if (!project) return

      const configuration = getProjectConfiguration(project)
      if (configuration.layers[layerIndex]) {
        configuration.layers[layerIndex].show_by_default = showLayer
      }
    },
    updateParam(
      state,
      action: PayloadAction<{ projectSlug: string, paramSlug: string, value: string | number | boolean }>,
    ) {
      const { projectSlug, paramSlug, value } = action.payload
      const project = state.list.find(p => p.slug === projectSlug)
      if (!project) return

      const configuration = getProjectConfiguration(project)
      const paramIndex = configuration.parameters?.findIndex(param => param.slug === paramSlug)
      if (paramIndex === -1) return

      configuration.parameters[paramIndex].value = value
    },
  },
  extraReducers: builder => {
    builder.addCase('projects/getProjects/pending', state => {
      state.loadingProjects = true
    })
    builder.addCase('projects/getProjects/fulfilled', state => {
      state.loadingProjects = false
    })
    builder.addCase('projects/getProjects/rejected', state => {
      state.loadingProjects = false
    })
  },
})

export const {
  setProjects,
  updateProject,
  updateLayerDisplay,
  updateParam,
} = projectsSlice.actions

export default projectsSlice.reducer
