import { ChangeEvent, useRef, useState } from 'react'

import './ColorPickerInput.scss'

type Props = {
  label: string
  defaultValue: string
  onChange: (color: string) => void
}

export default function ColorPicker({ label, defaultValue, onChange }: Props) {
  const inputRef = useRef<HTMLInputElement>(null)
  const [color, setColor] = useState(defaultValue)

  const handleChange = ({ target }: ChangeEvent<HTMLInputElement>) => {
    setColor(target.value)
    onChange(target.value)
  }

  const handleDisplay = () => {
    inputRef?.current?.click()
  }

  return (
    <div className="color-picker-wrapper input-wrapper">
      <label htmlFor={label}>{label}</label>
      <div className="inputs" onClick={handleDisplay}>
        <input className="input" readOnly disabled id={label} type="text" maxLength={7} value={color} />
        <div className="picker">
          <input ref={inputRef} type="color" value={color} onChange={handleChange} />
          <div className="preview" style={{ backgroundColor: color }} />
        </div>
      </div>
    </div>
  )
}
