import { useEffect, useState } from 'react'
import { useParams } from 'react-router-dom'
import lightTheme from 'assets/mapstyles/light.json'
import { getProject } from 'reducers/projects/thunks'
import { useAppDispatch, useAppSelector } from 'utils'
import { ActivePanel } from 'types'
import { useRoles } from 'services'
import { topBarTitleSignal, sideMenuOpenedSignal } from 'components'
import MapGL, { mapErrorSignal, mapStyleSignal } from './map/Map'
import Header from './header/ProjectHeader'
import Editor from './editor/EditorPanel'
import Config from './config/ConfigPanel'
import Layers from './layers/LayersPanel'
import Table from './table/TablePanel'

import './ProjectPage.scss'

export default function ProjectPage() {
  const dispatch = useAppDispatch()
  const params = useParams<{slug: string}>()
  const { list } = useAppSelector(state => state.projects)
  const { isAdmin, isConfigurator, hasOnlyAccess } = useRoles()
  const [panelDisplayed, setPanelDisplayed] = useState<ActivePanel>(isAdmin ? ActivePanel.CODE : ActivePanel.CONFIG)
  const project = list.find(({ slug }) => slug === params.slug)

  useEffect(() => {
    sideMenuOpenedSignal.value = false
    dispatch(getProject(params.slug, hasOnlyAccess))
  }, [])

  useEffect(() => {
    if (project) {
      topBarTitleSignal.value = project.name
    }
  }, [project])

  useEffect(() => () => {
    // Reset signals when unmounting project page component
    mapStyleSignal.value = lightTheme
    mapErrorSignal.value = []
  }, [])

  const handleChangePanel = (action: ActivePanel) => {
    setPanelDisplayed(action)
  }

  if (!project) return null

  return (
    <div id="project" className={hasOnlyAccess ? 'no-padding' : ''}>
      {(isAdmin || isConfigurator) && (
        <>
          <Header project={project} active={panelDisplayed} onChangePanel={handleChangePanel} />
          {panelDisplayed === ActivePanel.CODE && isAdmin && <Editor project={project} />}
          {panelDisplayed === ActivePanel.CONFIG && <Config project={project} />}
          {panelDisplayed === ActivePanel.LAYERS && <Layers project={project} />}
          {panelDisplayed === ActivePanel.TABLE && <Table project={project} />}
        </>
      )}
      <MapGL project={project} />
    </div>
  )
}
