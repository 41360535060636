import { Roles } from 'types'
import { useAppSelector } from 'utils'

export const useRoles = () => {
  const { appPermissions } = useAppSelector(state => state.user)
  const isAdmin = appPermissions.includes(Roles.ADMINISTRATOR)
  const isConfigurator = appPermissions.includes(Roles.CONFIGURATOR)

  return {
    isAdmin,
    isConfigurator,
    hasOnlyAccess: appPermissions.includes(Roles.ACCESS) && !isAdmin && !isConfigurator,
  }
}
