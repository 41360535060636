import { ReactElement, useEffect, useRef, useState } from 'react'
import { withErrorBoundary } from 'react-error-boundary'
import ReactMapGL, { LayerProps, MapError, MapEvent, MapRef, ViewportProps } from 'react-map-gl'
import { Feature } from 'geojson'
import { signal } from '@preact/signals-react'
import lightTheme from 'assets/mapstyles/light.json'
import {
  LAYER_ID_PREFIX,
  getLayersWithIds,
  getProjectConfiguration,
  printMapToPDF,
  removeSourceFromMap,
  renderConfigurationOnMap,
  addAssetsImagesToMap,
  transformRequest,
  useRoles,
} from 'services'
import { ProjectEditor, ProjectViewer } from 'types'
import FallbackComponent from './MapFallBack'
import MapPopup from './MapPopUp'
import Zoom from './MapZoom'

import './Map.scss'

type Props = {
  project: ProjectEditor | ProjectViewer
}

type FeatureEvent = Feature & {
  layer: LayerProps
}

/**
 * Signal used to communicate with configuration component
 * which is responsible for changing the map style
 * (this component is located in src/pages/project/config/index.tsx)
 */
export const mapStyleSignal = signal<unknown>(lightTheme)

/**
 * Signal used to communicate with editor component
 * which is responsible for providing error feedback to the user
 * (this component is located in src/pages/project/editor/index.tsx)
 */
export const mapErrorSignal = signal<(MapError['error'] & {url: string} | undefined)[]>([])

/**
 * Signal used to communicate with header actions component
 * (this component is located in src/pages/project/header/index.tsx)
 */
export const mapShouldPrintSignal = signal<boolean>(false)

/**
 * Signal used to communicate with header loading component
 * This signal is controlled by map idle and sourcedata events
 * (this component is located in src/pages/project/header/index.tsx)
 */
export const mapSourceLoadingSignal = signal<boolean>(false)

function MapGL({ project }: Props): ReactElement {
  const mapRef = useRef<MapRef | null>(null)
  const { hasOnlyAccess } = useRoles()
  const configuration = getProjectConfiguration(project)
  const [eventDisplayed, setEventDisplayed] = useState<MapEvent>(null)
  const [isLoaded, setIsLoaded] = useState(false)
  const [viewport, setViewport] = useState<ViewportProps>({
    latitude: 46.8025,
    longitude: 2.7643,
    zoom: 5.4389429465554,
    bearing: 0,
    pitch: 0,
  })

  useEffect(() => {
    if (!mapRef.current) return
    addAssetsImagesToMap(mapRef.current)
  }, [mapRef])

  useEffect(() => {
    if (isLoaded && !!mapRef.current && !!configuration) {
      renderConfigurationOnMap(mapRef.current, configuration)
    }

    mapRef.current.getMap().on('idle', () => { mapSourceLoadingSignal.value = false })
    mapRef.current.getMap().on('sourcedata', () => { mapSourceLoadingSignal.value = true })
  }, [isLoaded, mapRef, configuration, mapStyleSignal.value])

  useEffect(() => {
    if (mapShouldPrintSignal.value) {
      mapShouldPrintSignal.value = false
      printMapToPDF(mapRef.current, project?.name)
    }
  }, [mapShouldPrintSignal.value])

  const handleOnLoad = () => {
    setIsLoaded(true)
  }

  const handleViewportChange = (newViewport: ViewportProps) => {
    setViewport({ ...newViewport, transitionDuration: 0 })
  }

  const handleMapError = (mapError: MapError) => {
    if (mapError?.error?.message === 'Failed to fetch') return

    // eslint-disable-next-line dot-notation, prefer-destructuring
    const sourceId = mapError['sourceId']
    if (sourceId) removeSourceFromMap(mapRef.current, sourceId)

    const error = mapError.error as MapError['error'] & {url: string}
    mapErrorSignal.value = [...mapErrorSignal.value, error]
  }

  const handleMapClick = (event: MapEvent) => {
    const { layer, properties } = (event?.features?.at(0) || {}) as FeatureEvent
    if (layer && properties && layer.id.includes(LAYER_ID_PREFIX)) {
      setEventDisplayed(event)
    }
  }

  /*
  * Returns an array of layer ids that are interactive
  * (i.e. have a popup when clicked)
  * A check is made to ensure that the layer is not in an error state
  */
  const getInterractiveLayers = () => {
    if (!isLoaded) return []

    const ids = getLayersWithIds(configuration?.layers)
      ?.flatMap(layer => layer?.props?.map(prop => prop?.id))
      ?.filter(id => {
        const urlErrorCheck = !mapErrorSignal.value.find(({ url }) => url?.includes(id.split('-')[1]))
        const messageErrorCheck = !mapErrorSignal.value.find(({ message }) => message?.includes(id))
        return urlErrorCheck && messageErrorCheck
      })

    return ids
  }

  return (
    <div className={`map-gl${hasOnlyAccess ? ' no-project-header' : ''}`}>
      <Zoom zoom={viewport.zoom} />
      <ReactMapGL
        ref={mapRef}
        {...viewport}
        width="100%"
        height="100%"
        minZoom={5}
        transformRequest={transformRequest}
        onViewportChange={handleViewportChange}
        onLoad={handleOnLoad}
        onError={handleMapError}
        mapStyle={mapStyleSignal.value}
        onClick={handleMapClick}
        interactiveLayerIds={getInterractiveLayers()}
      >
        {eventDisplayed && (
          <MapPopup
            coordinate={eventDisplayed.lngLat}
            properties={eventDisplayed.features[0].properties}
            onClose={() => setEventDisplayed(null)}
          />
        )}
      </ReactMapGL>
    </div>
  )
}

export default withErrorBoundary(MapGL, {
  FallbackComponent,
})
