/* eslint-disable camelcase */
import { useDispatch } from 'react-redux'
import { ProjectEditor, ProjectViewer } from 'types'
import { getProjectConfiguration, getLayerInfo } from 'services'
import { updateLayerDisplay } from 'reducers/projects/slices'

import './LayersPanel.scss'

type Props = {
  project: ProjectEditor | ProjectViewer
}

export default function LayersPanel({ project }: Props) {
  const configuration = getProjectConfiguration(project)
  const { layers = [] } = configuration || {}
  const dispatch = useDispatch()

  const handleCheckboxChange = (index: number) => (event: React.ChangeEvent<HTMLInputElement>) => {
    const { checked } = event.target

    dispatch(updateLayerDisplay({
      projectSlug: project.slug,
      layerIndex: index,
      showLayer: checked,
    }))
  }

  return (
    <div className="layers">
      {layers?.map((layer, index) => {
        const { collection_slug, layer_slug, show_by_default } = layer || {}
        const id = `${collection_slug}_${layer_slug}-${index}`

        return (
          <div key={id} className="checkbox-container">
            <input
              id={id}
              type="checkbox"
              checked={show_by_default}
              onChange={handleCheckboxChange(index)}
            />
            <label htmlFor={id}>
              <div>
                <span>{collection_slug?.replaceAll('_', ' ')}</span>
                <span>{getLayerInfo(layer).description}</span>
              </div>
            </label>
          </div>
        )
      })}
    </div>
  )
}
