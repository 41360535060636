import { ReactComponent as Copy } from 'assets/icons/copy.svg'
import terms from 'assets/terms'
import { ToastLevel, toastSignal } from 'components'
import { postShareProject } from 'reducers/projects/thunks'
import { useAppDispatch } from 'utils'
import { Permission, Project, User } from 'types'
import ShareSearch from './SearchInput'

import './ShareModal.scss'

type Props = {
  project: Project
}

export default function ShareModal({ project }: Props) {
  const dispatch = useAppDispatch()

  const handleCopyLink = () => {
    window.navigator.clipboard.writeText(window.location.href)
    toastSignal.value = { message: terms.Common.linkCopied, severity: ToastLevel.SUCCESS }
  }

  const handleInvite = (user: User) => {
    dispatch(postShareProject(
      project.slug,
      { user_id: user.id, firstName: user.firstName, permission: user.role || Permission.READ_ONLY },
    ))
  }

  return (
    <div className="share-modal content">
      <div className="head">
        <h1 className="title">{terms.Modals.Share.title(project.name)}</h1>
        <a onClick={handleCopyLink} className="flex-center">
          <Copy />
          {terms.Common.copyLink}
        </a>
      </div>
      <ShareSearch onInvite={handleInvite} />
    </div>
  )
}
