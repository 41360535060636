import { get, post } from '@osrdata/app_core/dist/requests'
import { createApiThunk } from 'utils'
import { Configuration } from 'types'
import terms from 'assets/terms'
import { setProjects, updateProject } from './slices'

const getProjects = () => createApiThunk(
  'projects/getProjects',
  () => get('/guichet-carto/projects/'),
  setProjects,
)

const getProject = (slug: string, isViewer: boolean) => createApiThunk(
  'projects/getProject',
  () => get(`/guichet-carto/projects/${slug}/${isViewer ? 'view' : 'edit'}`),
  updateProject,
)

const postConfiguration = (slug: string, payload: Configuration) => createApiThunk(
  'projects/postProjectConfiguration',
  () => post(`/guichet-carto/configuration/${slug}`, payload),
  undefined,
  terms.Messages.success.projectSaved,
)

const postShareProject = (
  slug: string,
  payload: { user_id: string, firstName: string, permission: string },
) => createApiThunk(
  'projects/postShareProject',
  () => post(
    `/guichet-carto/projects/${slug}/share`,
    { user_id: payload.user_id, permission: payload.permission },
  ),
  undefined,
  terms.Messages.success.projectShared(slug, payload.firstName),
)

const postFile = (slug: string, file: File) => createApiThunk(
  'projects/postProjectFile',
  () => {
    const formData = new FormData()
    formData.append('file', file)

    return post(`/guichet-carto/import/${slug}`, formData, {
      headers: {
        Authorization: `Bearer ${localStorage.getItem('access_token')}`,
        'Content-Type': 'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet',
        'Content-Disposition': `attachment; filename=${encodeURIComponent(file.name)}`,
      },
    })
  },
)

const getFileStatus = (slug: string, taksId: string) => createApiThunk(
  'projects/getProjectFileImportStatus',
  () => get(`/guichet-carto/import/${slug}/status/${taksId}/`),
)

export {
  getProjects,
  getProject,
  postShareProject,
  postConfiguration,
  postFile,
  getFileStatus,
}
