/* Utils or UI related types definition */
import { Feature } from 'geojson'

/* Enum used to control the displayed panel on /project view */
export enum ActivePanel {
  CODE = 'CODE',
  CONFIG = 'CONFIG',
  LAYERS = 'LAYERS',
  TABLE = 'TABLE',
}

/* Type used in SelectInput component */
export type Option = {
  label?: string
  value: string | number
}

/* Type used to manipulate response from chartis call */
export type GeojsonResponse = {
  features: Feature[]
  next: string
  previous: string
}

export type ChartisInfo = {
  name: string,
  description: string,
  fields_details: ChartisField[]
}

export type ChartisField = {
  description: string
  name: string
  sql_type: string
  type: string
  views: string[]
}

/* List of available type field in chartis */
export type SqlType = 'integer' | 'double' | 'text' | 'boolean' | 'geom' | 'varchar_100' | 'varchar_200'
| 'timestamp_with_tz' | 'uuid' | 'bigint' | 'array_uuid' | 'jsonb' | 'array_text' | 'varchar_6' | 'array_varchar_40'
| 'varchar_40' | 'array_char_36' | 'varchar_4' | 'varchar_50' | 'varchar_10' | 'jsonb_as_text' | 'varchar_20'
| 'varchar_2' | 'char_1' | 'varchar_1' | 'varchar_36' | 'array_integer' | 'varchar_32' | 'array_varchar_6' | 'varchar_8'

export type DataGridType = 'string' | 'number' | 'date' | 'boolean' | 'array'

/* Enum used to regroup available filters for layer response from chartis */
export enum FilterOperators {
  EQ = 'eq',
  BBOX = 'bbox',
  BPOLYGON = 'bpolygon',
  ISNULL = 'isnull',
  GT = 'gt',
  GTE = 'gte',
  GT_OR_NULL = 'gt_or_null',
  GTE_OR_NULL = 'gte_or_null',
  LT = 'lt',
  LTE = 'lte',
  LT_OR_NULL = 'lt_or_null',
  LTE_OR_NULL = 'lte_or_null',
  NEQ = 'neq',
  IN = 'in',
  OVERLAPS = 'overlaps',
  LIKE = 'like',
  ILIKE = 'ilike',
  GEOM_TYPE_IN = 'geom_type_in',
}
