/* eslint-disable react/no-array-index-key */
import { useEffect, useState } from 'react'
import { normalize, useAppDispatch, useAppSelector } from 'utils'
import { getProjects } from 'reducers/projects/thunks'
import terms from 'assets/terms'
import { Project } from 'types'
import { DropDown, DropDownType, MenuDropDown, topBarTitleSignal, sideMenuOpenedSignal } from 'components'
import Card from './card/ProjectCard'
import SearchInput from './search/SearchInput'

import './ProjectPage.scss'

export default function Projects() {
  const [anchorEl, setAnchorEl] = useState<null | HTMLElement>(null)
  const [filter, setFilter] = useState<'DATE' | 'ALPHA' | null>(null)
  const [search, setSearch] = useState('')
  const { list, loadingProjects } = useAppSelector(state => state.projects)
  const dispatch = useAppDispatch()

  useEffect(() => {
    topBarTitleSignal.value = terms.Pages.Projects.title
    dispatch(getProjects())
  }, [])

  const dropdownActions = [
    {
      label: terms.Pages.Projects.dropdownLabels.date,
      handleClick: () => setFilter('DATE'),
    },
    {
      label: terms.Pages.Projects.dropdownLabels.alpha,
      handleClick: () => setFilter('ALPHA'),
    },
  ]

  const getFilteredProjects = () => {
    let filteredList = [...list]

    if (filter === 'DATE') {
      filteredList.sort((a, b) => {
        if (a.last_modified < b.last_modified) return 1
        if (a.last_modified > b.last_modified) return -1
        return 0
      })
    } else if (filter === 'ALPHA') {
      filteredList.sort((a, b) => {
        if (a.name < b.name) return -1
        if (a.name > b.name) return 1
        return 0
      })
    }

    if (search) {
      filteredList = filteredList.filter(({ name }) => normalize(name).includes(normalize(search)))
    }

    return filteredList
  }

  // Generate at least 6 empty skeleton card minus the number of project
  const getLoadingSkeleton = () => {
    if (!loadingProjects) return null

    return Array.from({ length: 6 - (list?.length || 0) }).map(
      (_, index) => <Card key={`loading-${index}`} project={{} as Project} pending />,
    )
  }

  return (
    <div id="projects" className={sideMenuOpenedSignal.value ? 'side-menu-opened' : ''}>
      <div className="actions">
        <SearchInput
          placeholder={terms.Pages.Projects.searchPlaceholder}
          onChange={(value: string) => setSearch(value)}
        />
        <DropDown
          type={DropDownType.CHEVRON}
          anchorEl={anchorEl}
          setAnchorEl={setAnchorEl}
          label={terms.Common.sortBy}
        >
          <MenuDropDown actions={dropdownActions} handleClose={() => setAnchorEl(null)} />
        </DropDown>
      </div>
      <div className="wrapper">
        {getFilteredProjects().map(project => <Card key={project.slug} project={project} />)}
        {getLoadingSkeleton()}
      </div>
    </div>
  )
}
