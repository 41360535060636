import { useEffect, useState } from 'react'

import '../wrapper.scss'
import './TextInput.scss'

type Props = {
  defaultValue: string
  label?: string
  type?: 'string' | 'number'
  align?: 'left' | 'center' | 'right'
  shouldReset?: boolean
  onChange: (value: string) => void
}

export default function Text({ defaultValue, label, type, align, shouldReset, onChange }: Props) {
  const [value, setValue] = useState(defaultValue)

  const handleChange = (event: React.ChangeEvent<HTMLInputElement>) => {
    const inputValue = type === 'number'
      ? event.target.value.replace(/\D/g, '')
      : event.target.value

    setValue(inputValue)
    onChange(inputValue)
  }

  useEffect(() => {
    if (shouldReset) {
      setValue('')
      onChange('')
    }
  }, [shouldReset])

  return (
    <div className={`text input-wrapper align-${align}`}>
      {label && <label htmlFor={label}>{label}</label>}
      <input
        className="input"
        type="text"
        value={value}
        onChange={handleChange}
      />
    </div>
  )
}

Text.defaultProps = {
  label: undefined,
  type: 'string',
  shouldReset: false,
  align: 'left',
}
