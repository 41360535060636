import terms from 'assets/terms'
import { ReactComponent as ErrorIcon } from 'assets/icons/error.svg'
import { Button } from 'components'

type Props = {
  reason: string
  onReset: () => void
}

export function Error({ reason, onReset }: Props) {
  return (
    <div className="error flex-column-center">
      <div>
        <ErrorIcon />
        <div>
          <h2>{terms.Pages.Import.errorTitle}</h2>
          <p>{terms.Pages.Import.errorSubtitle}</p>
        </div>
      </div>
      {reason && <p className="result">{reason}</p>}
      <Button
        text={terms.Pages.Import.backButton}
        onClick={onReset}
      />
    </div>
  )
}
