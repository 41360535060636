import { ChangeEvent, useState } from 'react'

import '../wrapper.scss'
import './SliderInput.scss'

type Props = {
  label: string
  defaultValue: number
  onChange: (value: number) => void
  max?: number
}

export default function Slider({ defaultValue, label, onChange, max }: Props) {
  const [sliderValue, setSliderValue] = useState(defaultValue)

  const handleChange = (event: ChangeEvent<HTMLInputElement>) => {
    const newValue = parseInt(event.target.value, 10)
    setSliderValue(newValue)
    onChange(newValue)
  }

  return (
    <div className="slider input-wrapper">
      <label htmlFor={label}>{label}</label>
      <div>
        <input
          className="input"
          readOnly
          disabled
          type="text"
          value={sliderValue}
        />
        <input
          type="range"
          min="0"
          max={max}
          step="1"
          value={sliderValue}
          onChange={handleChange}
        />
      </div>
    </div>
  )
}

Slider.defaultProps = {
  max: 30,
}
