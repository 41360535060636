/* eslint-disable camelcase */
import mapBack1 from 'assets/icons/map-back-01.png'
import mapBack2 from 'assets/icons/map-back-02.png'
import mapBack3 from 'assets/icons/map-back-03.png'
import lightTheme from 'assets/mapstyles/light.json'
import darkTheme from 'assets/mapstyles/dark.json'
import clearTheme from 'assets/mapstyles/clear.json'
import { Accordion, CheckInput, ColorPickerInput, SliderInput, TextInput } from 'components'
import terms from 'assets/terms'
import { Parameter, ProjectEditor, ProjectViewer, ParameterTypes } from 'types'
import { getProjectConfiguration } from 'services'
import { debounce } from 'lodash'
import { updateParam } from 'reducers/projects/slices'
import { useAppDispatch } from 'utils'
import { mapStyleSignal } from '../map/Map'
import MapCard from './MapCard'

import './ConfigPanel.scss'

type Props = {
  project: ProjectEditor | ProjectViewer
}

export default function ConfigPanel({ project }: Props) {
  const dispatch = useAppDispatch()
  const configuration = getProjectConfiguration(project)
  const parameters = configuration?.parameters || []
  const mapParam = parameters.find(({ type }) => type === ParameterTypes.MAP_BACKGROUND)
  const otherParams = parameters.filter(({ type }) => type !== ParameterTypes.MAP_BACKGROUND)
  const paramsByGroup = otherParams.reduce((acc, param) => {
    if (!acc[param.group]) {
      acc[param.group] = []
    }
    acc[param.group].push(param)
    return acc
  }, {} as { [key: string]: Parameter[] })

  const handleChangeMap = (mapStyle: unknown) => () => {
    mapStyleSignal.value = mapStyle
  }

  const debouncedHandleChangeParameter = debounce((slug: string, value: string | number | boolean) => {
    dispatch(updateParam({
      projectSlug: project.slug,
      paramSlug: slug,
      value,
    }))
  }, 300)

  const handleChangeParameter = (slug: string) => (value: string | number | boolean) => {
    debouncedHandleChangeParameter(slug, value)
  }

  const renderInput = (param: Parameter) => {
    const { name, slug, type, value } = param

    switch (type) {
      case ParameterTypes.COLOR:
        return (
          <ColorPickerInput
            key={slug}
            label={name}
            defaultValue={value as string}
            onChange={handleChangeParameter(slug)}
          />
        )
      case ParameterTypes.INTEGER:
        return (
          <SliderInput
            key={slug}
            label={name}
            defaultValue={value as number}
            onChange={handleChangeParameter(slug)}
          />
        )
      case ParameterTypes.BOOL:
        return (
          <CheckInput
            key={slug}
            label={name}
            defaultValue={value as boolean}
            onChange={handleChangeParameter(slug)}
          />
        )
      case ParameterTypes.TEXT:
        return (
          <TextInput
            key={slug}
            label={name}
            defaultValue={value as string}
            onChange={handleChangeParameter(slug)}
          />
        )
      default:
        return null
    }
  }

  return (
    <div className="config">
      {mapParam && (
        <Accordion title={mapParam.group}>
          <MapCard
            title={terms.Pages.Project.Config.cardTitle(1)}
            handleChangeMap={handleChangeMap(lightTheme)}
            backgroundImage={mapBack1}
            isSelected={mapStyleSignal.value === lightTheme}
          />
          <MapCard
            title={terms.Pages.Project.Config.cardTitle(2)}
            handleChangeMap={handleChangeMap(darkTheme)}
            backgroundImage={mapBack2}
            isSelected={mapStyleSignal.value === darkTheme}
          />
          <MapCard
            title={terms.Pages.Project.Config.cardTitle(3)}
            handleChangeMap={handleChangeMap(clearTheme)}
            backgroundImage={mapBack3}
            isSelected={mapStyleSignal.value === clearTheme}
          />
        </Accordion>
      )}
      {Object.entries(paramsByGroup).map(([group, params]) => (
        <Accordion title={group} key={group}>
          {params?.map(renderInput)}
        </Accordion>
      ))}
    </div>
  )
}
