import { Configuration, Filter, FilterOperators, ProjectEditor, ProjectViewer } from 'types'

/**
 * Retrieve the configuration of a project based on its type
 *
 * @param project Editor or Viewer project
 * @returns Configuration of the project
 */
export const getProjectConfiguration = (project: ProjectEditor | ProjectViewer): Configuration => {
  if (!project) return null
  return 'templatable_configuration' in project
    ? project.templatable_configuration
    : project.rendered_configuration
}

/**
 * Replace variables in layers with their matching parameter default value
 * Variable format: "<% parameter_slug %>"
 *
 * @param configuration project configuration
 * @returns configuration with variables replaced by their default value
 */
export function replaceVariables(configuration: Configuration): Configuration {
  const jsonString = JSON.stringify(configuration, (_, value) => {
    if (typeof value === 'string' && /<%[^{}]*%>/.test(value)) {
      const parameter = configuration?.parameters?.find(param => param.slug === value.replace(/[\s{}<%>]+/g, ''))
      return parameter ? parameter.value : value
    }

    return value
  })

  return JSON.parse(jsonString)
}

/**
 * Generate URLSearchParams object for filters
 *
 * @param filters list of filters
 * @returns URLSearchParams
 */
export function getParamsForFilters(filters: Filter[] = []): URLSearchParams {
  return new URLSearchParams(filters.reduce((acc: Record<string, string>, curr) => {
    const { field, operator, value } = curr
    const key = operator && FilterOperators[operator.toUpperCase()] && operator !== FilterOperators.EQ
      ? `${field}__${operator}`
      : field
    return { ...acc, [key]: value }
  }, {}))
}
