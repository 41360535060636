import { ChangeEvent, DragEvent, useRef } from 'react'
import { PayloadAction } from '@reduxjs/toolkit'
import { terms } from 'assets/terms'
import { ReactComponent as UploadIcon } from 'assets/icons/upload.svg'
import { TaskStatus } from 'types'
import { postFile } from 'reducers/projects/thunks'
import { useAppDispatch } from 'utils'
import { ToastLevel, toastSignal } from 'components'

type Props = {
  slug: string
  onTaskChange: (task: { uuid: string, status: TaskStatus }) => void
}

export function Upload({ slug, onTaskChange }: Props) {
  const dispatch = useAppDispatch()
  const fileInputRef = useRef<HTMLInputElement>(null)
  const allowedFileTypes = [
    '.csv',
    '.xls',
    '.xlsx',
    'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet',
    'application/vnd.ms-excel',
  ]

  const handleUpload = async (file: File) => {
    onTaskChange({ uuid: null, status: TaskStatus.IDLE })

    const response = await dispatch(
      postFile(slug, file),
    ) as PayloadAction<{ task_uuid: string }, null, null, { message: string }>

    if (response?.error?.message) {
      toastSignal.value = { message: terms.Messages.errors.fileUpload, severity: ToastLevel.ERROR }
    } else {
      onTaskChange({ uuid: response.payload.task_uuid, status: TaskStatus.PENDING })
    }
  }

  const handleDrop = async (event: DragEvent<HTMLDivElement>) => {
    event.preventDefault()

    const { files } = event.dataTransfer

    if (files.length === 1) {
      const file = files[0]
      const isValidFileType = allowedFileTypes.some(type => file.name.toLowerCase().endsWith(type))

      if (isValidFileType) {
        handleUpload(file)
      } else {
        toastSignal.value = {
          message: 'Invalid file type. Please drop only CSV or XLS files.',
          severity: ToastLevel.ERROR,
        }
      }
    } else {
      toastSignal.value = {
        message: 'Please drop only one file at a time.',
        severity: ToastLevel.ERROR,
      }
    }
  }

  const handleFileChange = async (event: ChangeEvent<HTMLInputElement>) => {
    if (event.target.files && event.target.files.length > 0) {
      handleUpload(event.target.files[0])
    }
  }

  const handleDragOver = (event: DragEvent<HTMLDivElement>) => {
    event.preventDefault()
  }

  const handleClick = () => {
    if (fileInputRef.current) {
      fileInputRef.current.click()
    }
  }

  return (
    <div
      className="upload flex-column-center"
      onDragOver={handleDragOver}
      onDrop={handleDrop}
      onClick={handleClick}
    >
      <input
        ref={fileInputRef}
        type="file"
        accept={allowedFileTypes.join(',')}
        onChange={handleFileChange}
        style={{ display: 'none' }}
        multiple={false}
      />
      <UploadIcon />
      { /* eslint-disable-next-line react/no-danger */}
      <p dangerouslySetInnerHTML={{ __html: terms.Pages.Import.uploadFormat }} />
    </div>
  )
}
