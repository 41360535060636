import { round } from 'lodash'
import { useEffect, useState } from 'react'
import terms from 'assets/terms'

type Props = {
  zoom: number
}

export default function Zoom({ zoom }: Props) {
  const [displayZoom, setDisplayZoom] = useState(false)

  useEffect(() => {
    setDisplayZoom(true)

    const timeout = setTimeout(() => {
      setDisplayZoom(false)
    }, 3000)

    return () => {
      clearTimeout(timeout)
    }
  }, [zoom])

  return (
    <p className={`map-zoom${displayZoom ? ' displayed' : ''}`}>
      <b>{`${terms.Pages.Project.Map.zoom} : `}</b>
      <b>{round(zoom, 1)}</b>
    </p>
  )
}
