import { ChangeEvent, useEffect, useState } from 'react'
import { debounce } from 'lodash'
import { get } from '@osrdata/app_core/dist/requests'
import terms from 'assets/terms'
import { Permission, User } from 'types'
import { DropDown, DropDownType, MenuDropDown, Button } from 'components'

type Props = {
  onInvite: (user: User) => void
}

export default function ShareSearch({ onInvite }: Props) {
  const [users, setUsers] = useState<User[]>([])
  const [searchValue, setSearchValue] = useState<string>('')
  const [userSelected, setUserSelected] = useState<User | null>(null)
  const [anchorEl, setAnchorEl] = useState<null | HTMLElement>(null)

  const dropdownActions = [
    {
      label: terms.Common.READ_ONLY,
      handleClick: () => {
        if (!userSelected) return
        setUserSelected({ ...userSelected, role: Permission.READ_ONLY })
      },
    },
    {
      label: terms.Common.READ_WRITE,
      handleClick: () => {
        if (!userSelected) return
        setUserSelected({ ...userSelected, role: Permission.READ_WRITE })
      },
    },
  ]

  useEffect(() => {
    const delayedSearch = debounce(async () => {
      if (userSelected) return
      const response = await get<{ results: User[] }>('/cerbere/users/', { search: searchValue })
      setUsers(response.results)
    }, 500)

    if (searchValue.length >= 3) {
      delayedSearch()
    } else {
      setUsers([])
    }

    return delayedSearch.cancel
  }, [searchValue, userSelected])

  const handleSearch = ({ target }: ChangeEvent<HTMLInputElement>) => {
    setSearchValue(target.value)
    setUserSelected(null)
  }

  const handleUserSelect = (user: User) => () => {
    setSearchValue(`${user.firstName} ${user.lastName}`)
    setUserSelected(user)
    setUsers([])
  }

  const handleInvite = () => {
    onInvite(userSelected)
    setUserSelected(null)
    setSearchValue('')
  }

  return (
    <div className={`share-search-bar${users.length > 0 ? ' has-results' : ''}`}>
      <input
        type="text"
        value={searchValue}
        onChange={handleSearch}
      />
      <DropDown
        type={DropDownType.CHEVRON_LIGHT}
        anchorEl={anchorEl}
        setAnchorEl={setAnchorEl}
        label={terms.Common[userSelected?.role || Permission.READ_ONLY]}
        disabled={!userSelected}
      >
        <MenuDropDown actions={dropdownActions} handleClose={() => setAnchorEl(null)} />
      </DropDown>
      <Button text={terms.Common.invit} onClick={handleInvite} disabled={!userSelected} />
      <div className="results">
        {users.map(user => (
          <div className="user" key={user.id} onClick={handleUserSelect(user)}>
            <p>{`${user.firstName} ${user.lastName}`}</p>
            <p className="email">{user.email}</p>
          </div>
        ))}
      </div>
    </div>
  )
}
