import { ReactElement, useEffect } from 'react'
import { useNavigate, useLocation } from 'react-router-dom'
import { signal } from '@preact/signals-react'
import terms from 'assets/terms'
import { ReactComponent as EyeIcon } from 'assets/icons/eye.svg'
import { ReactComponent as HelpIcon } from 'assets/icons/help.svg'

import './SideMenu.scss'

/**
 * Signal used to control the side menu open state
 */
export const sideMenuOpenedSignal = signal<boolean>(false)

function SideMenu(): ReactElement {
  const location = useLocation()
  const navigate = useNavigate()

  useEffect(() => {
    sideMenuOpenedSignal.value = location.pathname === '/projects' || location.pathname === '/'
  }, [location.pathname])

  return (
    <div data-testid="side-menu" className={`side-menu${sideMenuOpenedSignal.value ? ' opened' : ''}`}>
      <a
        className={`${window.location.pathname === '/' || window.location.pathname === '/projects' ? 'active' : ''}`}
        onClick={() => navigate('/projects')}
      >
        <EyeIcon />
        <span>{terms.SideMenu.links.myViz}</span>
      </a>
      <button type="button" onClick={() => { /* TODO: Implement */ }}>
        <HelpIcon />
        <span>{terms.SideMenu.links.help}</span>
      </button>
    </div>
  )
}

export default SideMenu
