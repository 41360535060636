import { useEffect, useState } from 'react'
import { Option } from 'types'

import '../wrapper.scss'
import './SelectInput.scss'

type Props = {
  defaultValue: string
  label?: string
  options: Option[]
  shouldReset?: boolean
  onChange: (value: string | number) => void
}

export default function Select({ defaultValue, label, options, shouldReset, onChange }: Props) {
  const [value, setValue] = useState(defaultValue)

  useEffect(() => {
    if (shouldReset) {
      onChange(options[0].value)
    }
  }, [shouldReset])

  const handleChange = (event: React.ChangeEvent<HTMLSelectElement>) => {
    setValue(event.target.value)
    onChange(event.target.value)
  }

  return (
    <div className="select input-wrapper">
      {label && <label htmlFor={label}>{label}</label>}
      <select
        className="input"
        value={value}
        onChange={handleChange}
      >
        {options.map(option => (
          <option
            key={option.value}
            value={option.value}
          >
            {option.label || option.value}
          </option>
        ))}
      </select>
    </div>
  )
}

Select.defaultProps = {
  label: undefined,
  shouldReset: false,
}
