type Props = {
  Icon: React.FunctionComponent<React.SVGProps<SVGSVGElement>>
  action: () => void
  active?: boolean
  hidden?: boolean
}

export default function Action({ Icon, action, active, hidden }: Props) {
  return (
    <div
      className={`flex-center icon-action${active ? ' active' : ''}${hidden ? ' hidden' : ''}`}
      onClick={() => !hidden && action()}
    >
      <Icon />
    </div>
  )
}

Action.defaultProps = {
  active: false,
  hidden: false,
}
