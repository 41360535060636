import terms from 'assets/terms'
import { ReactComponent as SuccessIcon } from 'assets/icons/success.svg'
import { Button } from 'components'

type Props = {
  rowCount: number
  onNavigate: () => void
}

export function Success({ rowCount, onNavigate }: Props) {
  return (
    <div className="success flex-column-center">
      <div>
        <SuccessIcon />
        <div>
          <h2>{terms.Pages.Import.successTitle}</h2>
          <p>{terms.Pages.Import.successSubtitle}</p>
        </div>
      </div>
      {rowCount > 0 && <p className="result">{`${rowCount} ${terms.Pages.Import.processedLines}`}</p>}
      <Button
        text={terms.Pages.Import.successButton}
        onClick={onNavigate}
      />
    </div>
  )
}
