/* eslint-disable react/no-array-index-key */
/* eslint-disable camelcase */
import terms from 'assets/terms'
import { Accordion, Button, ButtonStyle } from 'components'

type Props = {
  rejected: {
    rejected_data: {
      [key: string]: string | string[] | number | number[] | boolean | null
    }[]
    rejected_reason: string
  }[]
  onReset: () => void
  onNavigate: () => void
}

export function Rejected({ rejected, onReset, onNavigate }: Props) {
  return (
    <div className="rejected flex-column-center">
      {(!Array.isArray(rejected) || !rejected?.at(0)?.rejected_data?.at(0)) && (
        <>
          <h3>{terms.Pages.Import.errorPartiallyRejected}</h3>
          <p>{`(${terms.Pages.Import.errorNoData})`}</p>
        </>
      )}
      {Array.isArray(rejected) && rejected?.map(({ rejected_data, rejected_reason }, i) => (
        <Accordion
          key={i}
          title={rejected_reason}
          defaultExpanded={false}
        >
          {Array.isArray(rejected_data) && rejected_data?.map((data, j) => (
            <div key={j} className="line flex-column-center">
              {Object.entries(data).map(([key, value]) => (
                <div key={key} className="data">
                  <b>{key}</b>
                  {` : ${value}`}
                </div>
              ))}
            </div>
          ))}
        </Accordion>
      ))}
      <Button
        text={terms.Pages.Import.backButton}
        style={ButtonStyle.light}
        onClick={onReset}
      />
      <Button
        text={terms.Pages.Import.showDataButton}
        onClick={onNavigate}
      />
    </div>
  )
}
