import { MenuItem } from '@mui/material'
import { ReactElement } from 'react'

interface MenuDropdownProps {
  actions: {
    label: string
    handleClick: () => void
  }[]
  handleClose: () => void
}

function MenuDropdown({ actions, handleClose }: MenuDropdownProps): ReactElement {
  const handleAction = (action: {label: string, handleClick: () => void}) => () => {
    handleClose()
    action.handleClick()
  }

  return (
    <>
      {actions.map(action => (
        <MenuItem
          key={action.label}
          onClick={handleAction(action)}
        >
          {action.label}
        </MenuItem>
      ))}
    </>
  )
}

export default MenuDropdown
