import { Button, ButtonStyle } from 'components'
import terms from 'assets/terms'
import { modalSignal } from '../ModalWrapper'

import './SaveModal.scss'

type Props = {
  projectName: string
  onConfirm: () => void
}

export default function SaveModal({ projectName, onConfirm }: Props) {
  const handleHideModal = () => {
    modalSignal.value = undefined
  }

  return (
    <div className="save-modal content">
      <h1 className="title">{terms.Modals.Save.title(projectName)}</h1>
      <div className="flex-center">
        <Button style={ButtonStyle.light} onClick={handleHideModal} text={terms.Common.cancel} />
        <Button onClick={onConfirm} text={terms.Common.save} />
      </div>
    </div>
  )
}
