import { ReactElement, StrictMode, Suspense, useEffect, useState } from 'react'
import { BrowserRouter, Navigate, Route, Routes, useLocation } from 'react-router-dom'
import '@preact/signals-react/auto'
import { auth } from '@osrdata/app_core'
import { Roles } from 'types'
import { useAppDispatch, useAppSelector } from 'utils'
import { terms } from 'assets/terms'
import { getChartisInfo } from 'services/chartis'
import {
  Loader, TopBar, ToastMessage, SideMenu, ModalWrapper, modalSignal, toastSignal,
} from 'components'
import { DeniedPage, ProjectsPage, ProjectPage, ImportPage } from 'pages'
import 'moment-timezone'

import 'App.scss'

const APP_NAME = 'guichet-carto'
const ACCESS_PERM = Roles.ACCESS

/** Externalize router logic in its own component
 * in order to use useLocation hook
 */
function Router() {
  const location = useLocation()

  useEffect(() => {
    // reset root signals when changing route
    modalSignal.value = undefined
    toastSignal.value = { ...toastSignal.value, timeout: 0 }
  }, [location.pathname])

  return (
    <Routes>
      <Route element={<ProjectsPage />} path="/" />
      <Route element={<ProjectsPage />} path="/projects" />
      <Route element={<ProjectPage />} path="/projects/:slug" />
      <Route element={<ImportPage />} path="/projects/:slug/import" />
      <Route path="*" element={<Navigate to="/" />} />
    </Routes>
  )
}

export default function App(): ReactElement {
  const dispatch = useAppDispatch()
  const { isLogged, appPermissions, isLoading } = useAppSelector(state => state.user)
  const [accessDenied, setAccessDenied] = useState(false)

  useEffect(() => {
    dispatch(auth.attemptLoginOnLaunch())
  }, [])

  useEffect(() => {
    if (isLogged && appPermissions.length > 0 && !appPermissions.includes(ACCESS_PERM)) {
      setAccessDenied(true)
    }

    if (isLogged && !accessDenied && !isLoading) {
      getChartisInfo()
    }
  }, [isLogged, appPermissions, isLoading])

  const renderApp = () => {
    if (isLoading) return <Loader message={terms.Common.loading} standalone />
    if (accessDenied) return <DeniedPage />

    return (
      <>
        <Router />
        <ModalWrapper />
        <SideMenu />
      </>
    )
  }

  if (!isLoading && !isLogged) return null

  return (
    <StrictMode>
      <Suspense fallback={<Loader />}>
        <BrowserRouter>
          {!isLoading && <TopBar appName={APP_NAME} hasAccess={!accessDenied} />}
          <div id="app">{renderApp()}</div>
          <ToastMessage />
        </BrowserRouter>
      </Suspense>
    </StrictMode>
  )
}
