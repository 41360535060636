/* eslint-disable jsx-a11y/no-noninteractive-element-interactions */
import { useEffect, useRef, useState } from 'react'
import { ReactComponent as Chevron } from 'assets/icons/chevron.svg'

import './Accordion.scss'

type Props = {
  title: string
  children: React.ReactNode
  defaultExpanded?: boolean
}

export default function Accordion({ title, children, defaultExpanded }: Props) {
  const [height, setHeight] = useState(0)
  const [expanded, setExpanded] = useState(defaultExpanded)
  const accordionRef = useRef<HTMLDivElement>(null)
  const [isTransitionEnabled, setIsTransitionEnabled] = useState(false)

  useEffect(() => {
    if (accordionRef.current) {
      const { offsetHeight } = accordionRef.current
      setHeight(offsetHeight)
      accordionRef.current.style.height = `${offsetHeight}px`

      requestAnimationFrame(async () => {
        setExpanded(defaultExpanded)
        accordionRef.current.style.height = defaultExpanded ? `${offsetHeight}px` : '0px'
        setIsTransitionEnabled(true)
      })
    }
  }, [accordionRef])

  const handleToggle = () => {
    const { offsetHeight } = accordionRef.current
    if (offsetHeight > 0) {
      setExpanded(false)
      accordionRef.current.style.height = '0px'
    } else {
      setExpanded(true)
      accordionRef.current.style.height = `${height}px`
    }
  }

  return (
    <div className={`accordion${expanded ? ' expanded' : ''}${isTransitionEnabled ? ' enable-transition' : ''}`}>
      <h3 onClick={handleToggle}>
        <span>{title}</span>
        <Chevron />
      </h3>
      <div className="wrapper" ref={accordionRef}>
        {children}
      </div>
    </div>
  )
}

Accordion.defaultProps = {
  defaultExpanded: true,
}
